import { styled, Drawer, MenuList } from '@mui/material';
import { APP_BAR_HEIGHT } from '@youscience/brightpath-header';

export const StyledSidebarWrap = styled(Drawer)({
  width: '18.5rem',
  flexShrink: 0,

  '.MuiDrawer-paper': {
    marginTop: APP_BAR_HEIGHT,
    backgroundColor: 'white',
    width: '18.5rem',
  },
});

export const StyledSkeletonList = styled(MenuList)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  height: '100%',
});
