import { Suspense, lazy, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { RouterProvider } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { educationConnectionsTheme as theme } from '@youscience/khaleesi';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { AppRoutes } from '@routes/AppRoutes';
import useAppInitStore from '@store/AppInitStore';
import { QueryClientProvider } from './providers/QueryClientProvider';

import 'core-js/proposals/promise-with-resolvers';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_TRACKING_ID,
};

TagManager.initialize(tagManagerArgs);

const Notification = lazy(() => import('@components/Notification'));

function App() {
  const { initialize } = useAppInitStore();

  useEffect(() => {
    void initialize();
  }, [initialize]);

  return (
    <QueryClientProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={AppRoutes()} />
        <Suspense>
          <Notification />
        </Suspense>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
