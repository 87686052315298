import useUserInfoStore from '@store/UserInfoStore';

export interface GaEventArgs {
  eventType: string;
  props?: { [key: string]: string | number | undefined };
}

export const trackEvent = (eventArgs: GaEventArgs) => {
  const { eventType, props } = eventArgs;

  let data: { [key: string]: string | number | undefined } = {
    event: eventType,
    userId: useUserInfoStore.getState().userInfo.userId,
    category: window.location.pathname.split('/')[1],
  };

  if (props) {
    data = Object.assign(data, props);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
  (window as any).dataLayer.push(data);
};
