/* eslint-disable @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-assignment */
import { UserDocument } from '@youscience/user-service-common';
import { AxiosResponse } from 'axios';

import { GUS_BASE_URL } from '@constants/externalRoutes';

import { Api } from './api.service';
import { Subset } from '../../types';

class UserService extends Api {
  constructor() {
    super({
      withCredentials: true,
      baseURL: GUS_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  public updateUser = (userProfile: Subset<Pick<UserDocument, 'profile'>['profile']>): Promise<void | UserDocument> => {
    return this.patch<UserDocument, Subset<UserDocument>, AxiosResponse<UserDocument>>(`/me`, { profile: userProfile })
      .then(this.success)
      .catch(this.error);
  };
}

export const userService = new UserService();
