export const DESKTOP_PER_PAGE_OPTIONS = [60, 90, 120];
export const MOBILE_PER_PAGE_OPTIONS = [20, 40, 60];

export const ORDER_BY_EARLIEST = 'Earliest';
export const ORDER_BY_LATEST = 'Latest';

export const SLIDER_ITEMS_LIMIT = 12;

export const CLARIFY_COST_TOOLTIP =
  "The Cost per Year is the average cost that a student who receives federal financial aid pays to cover expenses (e.g., tuition, living expenses) to attend a school. Net price is the school's cost of attendance minus any grants and scholarships received. For public schools, this is only the average cost for in-state students.";

export const CURRENT_YEAR = new Date().getFullYear();
export const CURRENT_MONTH = new Date().getMonth();

let attendingYear = CURRENT_YEAR;

if (CURRENT_MONTH > 5) {
  attendingYear += 1;
}

export const ATTENDING_YEAR = attendingYear;

export const ALL_TYPES_VALUE = 'All types';

export const EDC_HASH = '#edc';
export const ECP_HASH = '#ecp';
