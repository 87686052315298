import { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';

import useHomePageStore from '@store/HomePageStore';
import useAppInitStore from '@store/AppInitStore';

import { ROUTES } from '@constants/ROUTES';
import { SidebarSkeleton } from '@components/SidebarSkeleton';

const PlannerSidebar = lazy(() => import('@components/PlannerSidebar'));
const MobilePlannerSidebar = lazy(() => import('@components/MobilePlannerSidebar'));
const RecommenderSpaceSidebar = lazy(() => import('@components/RecommenderSpaceSidebar'));
const MobileRecommenderSpaceSidebar = lazy(() => import('@components/MobileRecommenderSpaceSidebar'));

export const useSidebarInit = () => {
  const location = useLocation();
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { id } = useHomePageStore((state) => state.landingPageSchoolData);

  const isPlannerRoute = !id && location.pathname.includes(ROUTES.EDUCATION_PLANNER);
  const isRecommenderSpaceRoute = !id && location.pathname.includes(ROUTES.RECOMMENDER_SPACE);
  const shouldShowSidebar = isPlannerRoute || isRecommenderSpaceRoute;

  const { isLoading: isInitialDataLoading } = useAppInitStore();

  const isMobileSidebarRoute = shouldShowSidebar && isMobileScreen;

  let desktopSidebar: React.ReactNode | null = !shouldShowSidebar ? null : <SidebarSkeleton />;
  let mobileSidebar: React.ReactNode | null = !shouldShowSidebar ? null : <SidebarSkeleton isMobile />;

  if (!isInitialDataLoading && shouldShowSidebar) {
    if (isPlannerRoute && !isMobileScreen) {
      desktopSidebar = (
        <Suspense fallback={<SidebarSkeleton />}>
          <PlannerSidebar />
        </Suspense>
      );
    } else if (isRecommenderSpaceRoute && !isMobileScreen) {
      desktopSidebar = (
        <Suspense fallback={<SidebarSkeleton />}>
          <RecommenderSpaceSidebar />
        </Suspense>
      );
    }

    if (isPlannerRoute && isMobileScreen) {
      mobileSidebar = (
        <Suspense fallback={<SidebarSkeleton isMobile />}>
          <MobilePlannerSidebar />
        </Suspense>
      );
    } else if (isRecommenderSpaceRoute && isMobileScreen) {
      mobileSidebar = (
        <Suspense fallback={<SidebarSkeleton isMobile />}>
          <MobileRecommenderSpaceSidebar />
        </Suspense>
      );
    }
  }

  return {
    isMobileSidebarRoute,
    isMobileScreen,
    isInitialDataLoading,
    desktopSidebar,
    mobileSidebar,
  };
};
