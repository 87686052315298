// import { FormAddressSchema } from '@constants';
import { AccessDocument, AccessRoleEnum, UserDocument, UserDocumentWithAccess } from '@youscience/user-service-common';

export type PartialUser = Pick<UserDocument, 'userId' | 'fullName'>;

export interface ImpersonatedBy {
  access: AccessDocument[];
  fullName: string;
  userId: string;
}

export type UserMeResponse = UserDocumentWithAccess & { impersonatedBy: ImpersonatedBy };

export interface CurrentAccess {
  accessDocumentId: string;
  constraintTags?: string[];
  role: AccessRoleEnum;
  tenantId: string;
  tenantName?: string;
  commonAppEnabled: boolean;
  userId: string;
}

export interface PreferredAccess {
  currentAccess: AccessDocument['_id'];
}

export enum UserRoles {
  Admin = 'Admin',
  Staff = 'Staff',
  Proctor = 'Proctor',
  Learner = 'Learner',
  Alumni = 'Alum',
}
