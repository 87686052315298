import useAuthStore, { ProfileWithExtendedData } from '@store/AuthStore';
import { CURRENT_YEAR, CURRENT_MONTH } from '@constants';

export const getGraduationYear = (grade: number) => {
  if (grade === -1) return 0;

  const graduationYear = CURRENT_YEAR + (12 - grade);

  if (CURRENT_MONTH > 5) {
    return graduationYear + 1;
  }

  return graduationYear;
};

export const isAlreadyGraduated = () => {
  const { userData } = useAuthStore.getState().authSession;
  const { gradeLevel, plan } = (userData?.profile ?? {}) as ProfileWithExtendedData;

  return !!(!gradeLevel && (plan?.now || plan?.future?.length));
};
