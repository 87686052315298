import { Outlet } from 'react-router-dom';
import { Footer } from '@youscience/brightpath-header';

import { useSidebarInit } from '@hooks/useSidebarInit';

import { Header } from './components';

import { StyledLayoutWrap, StyledMain, StyledFooter } from './Layout.styles';
import { Suspense } from 'react';

export const Layout = () => {
  const { isMobileScreen, isMobileSidebarRoute, mobileSidebar, desktopSidebar } = useSidebarInit();

  return (
    <>
      <Header />

      <StyledLayoutWrap isRowDirection={!isMobileSidebarRoute}>
        <Suspense fallback={null}>{isMobileScreen ? mobileSidebar : desktopSidebar}</Suspense>

        <StyledMain isNeedToAddPaddingTop={isMobileSidebarRoute}>
          <Outlet />
        </StyledMain>
      </StyledLayoutWrap>

      {isMobileScreen ? <Footer /> : <StyledFooter />}
    </>
  );
};
