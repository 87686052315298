import { createWithEqualityFn } from 'zustand/traditional';

import { School, UserPreferences } from '@youscience/college-service-common';

import { baseService } from '@services/base.service';
import useAuthStore from '@store/AuthStore';

export interface UserLocation {
  latitude: string;
  longitude: string;
}

export interface UserInfo {
  userId: number;
  phone: string;
  email: string;
  notifyTenantAvailable: boolean;
}

export interface UserInfoStore {
  userInfo: UserInfo;
  userPreferences: UserPreferences;
  userLocation: UserLocation;
  nearbySchools: School[];
  setUserInfo: (userData: UserInfo) => Promise<void>;
  setUserPreferences: (userPreferences: UserPreferences) => void;
  setUserLocation: (UserLocation: UserLocation) => void;
  setNearbySchools: (nearbySchools: School[]) => void;
  setSchoolSharingNotificationRead: () => void;
  getPostalCode: () => string | undefined;
}

const initialState = {
  userLocation: {} as UserLocation,
  userInfo: {} as UserInfo,
} as UserInfoStore;

export const useUserInfoStore = createWithEqualityFn<UserInfoStore>()(
  (set, get) => ({
    ...initialState,

    getPostalCode: () => {
      let postalCode;

      const addresses = useAuthStore.getState().authSession.userData?.profile?.addresses;
      const primaryAddress = addresses?.filter((address) => {
        return address.isPrimary;
      });

      if (primaryAddress && primaryAddress.length > 0) {
        postalCode = primaryAddress[0].postCode;
      } else if (addresses && addresses.length > 0) {
        postalCode = addresses[0].postCode;
      }

      return postalCode;
    },

    setUserInfo: async (userInfo: UserInfo) => {
      const userData: Partial<UserInfoStore> = { userInfo };

      const postalCode = get().getPostalCode();

      if (postalCode) {
        const userLocation = await baseService.getAsync<UserLocation>(`/coordinates/${postalCode}`);

        const longitudeIsValid = !Number.isNaN(Number(userLocation.longitude));
        const latitudeIsValid = !Number.isNaN(Number(userLocation.latitude));

        userData.userLocation = userLocation;

        if (longitudeIsValid && latitudeIsValid) {
          const filtersUrl =
            'classesOffered=4' +
            '&isVocationalSchool=false' +
            '&carnegieSizeSettings=2-year,4-year' +
            '&institutionTypes=Public,Private (non-profit)';

          // eslint-disable-next-line max-len
          const nearbySchoolsUrl = `/nearby?${filtersUrl}&longitude=${userLocation.longitude}&latitude=${userLocation.latitude}`;

          const data = await baseService.getAsync<School[]>(nearbySchoolsUrl);

          userData.nearbySchools = data;
        }
      }

      set({
        ...userData,
      });
    },

    setSchoolSharingNotificationRead: () => {
      void baseService.patchAsync(`/user/preferences`, {
        notifyWhenTenantAssigned: false,
      });
    },

    setUserPreferences: (userPreferences: UserPreferences) => {
      set({
        userPreferences,
      });
    },
  }),
  Object.is,
);
