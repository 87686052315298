import { locations } from '@youscience/college-service-common';

import { Option } from '@interfaces';
import { getOptions } from '@utils/form';

interface State {
  label: string;
  value: string;
  description: string;
}

const outlyingAreas = 'Outlying areas';

const formatStatesList = (list: State[]) =>
  getOptions(list, 'description', 'value').sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

export const getStates = (): { states: Option[]; outlyingStates: Option[] } => {
  const states: State[] = [];
  const outlyingStates: State[] = [];

  locations.forEach((location) => {
    if (location.label === outlyingAreas) {
      outlyingStates.push(...location.states);
    } else {
      states.push(...location.states);
    }
  });

  return {
    states: <Option[]>formatStatesList(states),
    outlyingStates: <Option[]>formatStatesList(outlyingStates),
  };
};

const { states, outlyingStates } = getStates();

export const STATES_OF_USA = [...states, { value: '', label: '', isDisabled: true }, ...outlyingStates];
