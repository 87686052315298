import { Skeleton } from '@mui/material';
import { CoreBox } from '@youscience/khaleesi';
import { StyledSidebarWrap, StyledSkeletonList } from './SidebarSkeleton.styles';

interface SidebarSkeletonProps {
  isMobile?: boolean;
}

export const SidebarSkeleton = ({ isMobile = false }: SidebarSkeletonProps) => {
  return isMobile ? (
    <StyledSkeletonList>
      <CoreBox sx={{ padding: '0 1rem' }}></CoreBox>
    </StyledSkeletonList>
  ) : (
    <StyledSidebarWrap variant="permanent" open={true}>
      <CoreBox sx={{ padding: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Skeleton variant="circular" width={32} height={32} />
      </CoreBox>
      <CoreBox sx={{ padding: '0 1rem' }}></CoreBox>
    </StyledSidebarWrap>
  );
};
